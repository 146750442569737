import React, { useState, useEffect } from 'react'
import './TransactionPage.css'
import { useHistory, Redirect } from 'react-router-dom'
import { Center, Box } from '@chakra-ui/react'
import TopClaims from '../Top/TopClaims'
import api from '../core/api'
import Pagination from './Pagination'

function TransactionPage() {
  const [transactions, setTransactions] = useState([])
  const [filteredTransactions, setFilteredTransactions] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [transactionsPerPage, setTransactionsPerPage] = useState(7)
  const [refreshKey, setRefreshKey] = useState(0)
  const [secondLoad, setSecondLoad] = useState(false)

  //Get Current Posts
  const indexOfLastTransaction = currentPage * transactionsPerPage
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage
  //const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction)

  //Change page

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const handleNextPage = () => {
    if (currentPage === transactions.length / transactionsPerPage) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleLastPage = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  let history = useHistory()

  useEffect(async () => {
    try {
      const firstLoad = await api.post('/api.php', {
        action: 'get_transactions',
        body: {
          limit: 10
        }
      })

      setTransactions(firstLoad.data.data)
      setSecondLoad(true)
    } catch (error) {
      console.warn(error)
    }
  }, [])

  useEffect(() => {
    // console.log("members", members);
    setFilteredTransactions(transactions)
  }, [transactions])

  useEffect(async () => {
    if (secondLoad) {
      try {
        const resulta = await api.post('/api.php', {
          action: 'get_transactions',
          body: {}
        })

        setTransactions(resulta.data.data)
        setSecondLoad(true)
      } catch (error) {
        console.warn(error)
      }
    }
  }, [secondLoad])

  const filterTransactions = (filterForm) => {
    let newTransactions = transactions
    if (
      filterForm.member.toString().length > 0 ||
      filterForm.prov.toString().length > 0 ||
      filterForm.auth_no.toString().length > 0
    ) {
      newTransactions = transactions.filter(
        (m) =>
          (filterForm.member.toString().length > 0 &&
            `${m.member.first_names} ${m.member.last_name}`
              .toString()
              .toLowerCase()
              .includes(filterForm.member.toString().toLowerCase())) ||
          (filterForm.member.toString().length > 0 &&
            `${m.member.member_no}`.toString().includes(filterForm.member.toString())) ||
          (filterForm.prov.toString().length > 0 &&
            m.provider.name.toString().includes(filterForm.prov.toString())) ||
          (filterForm.auth_no.toString().length > 0 &&
            m.auth_no
              .toString()
              .toLowerCase()
              .includes(filterForm.auth_no.toString().toLowerCase()))
      )
    }

    // console.log("newMembers", newMembers);
    setFilteredTransactions(newTransactions)
  }

  return (
    <div className="main-page">
      <Box style={{ height: '200px' }}>
        <TopClaims onSearch={filterTransactions} setCurrentPage={setCurrentPage} />
      </Box>

      <Box style={{ width: '100%' }}>
        <Center>
          <table className="main_table">
            <thead className="transactions_head">
              <th>Membro</th>
              <th>Número de apólice</th>
              <th>Estado</th>
              <th>Autorização</th>
              <th>Data</th>
            </thead>

            {/*{transactions}*/}
            {filteredTransactions
              .slice(indexOfFirstTransaction, indexOfLastTransaction)
              .map((e) => {
                let authyz=""
                if(e.auth_no.includes("Reembolso")){
                    authyz="Reembolso"
                }
                return (
                  <tr
                    className="main_table_inside"
                    onClick={() =>
                      history.push({
                        pathname: '/transactionDetail',
                        // search: '?query=abc',
                        state: {
                          provider_id: e.provider_id,
                          auth_no: e.auth_no,
                          member_id: e.member.id,
                          date: e.date,
                          time: e.time
                        }
                      })
                    }
                  >
                    <td>
                      {e.member.first_names} {e.member.last_name}
                      <span className="span_member">{e.member.member_no}</span>
                    </td>
                    <td>{e.member.policy_no}</td>
                    <td>
                      {e.t_status.status}
                      <span className="span_member">{e.provider.name}</span>
                    </td>
                    <td>
                      {authyz===""?(e.auth_no):(authyz)}
                      <span className="span_member">{e.auth_by}</span>
                    </td>
                    <td>
                      {e.date} <span className="span_member">{e.time}</span>{' '}
                    </td>
                    <td> </td>
                  </tr>
                )
              })}
          </table>
        </Center>
      </Box>
      <br/>
      <Pagination
        setTransactionsPerPage={setTransactionsPerPage}
        handleNextPage={handleNextPage}
        currentPage={currentPage}
        handleLastPage={handleLastPage}
        transactionsPerPage={transactionsPerPage}
        totalTransactions={transactions.length}
        paginate={paginate}
      />
    </div>
  )
}

export default TransactionPage
