import axios from 'axios'

//const BASE_URL = 'https://siglonet.com/bettercare'
 const BASE_URL = 'https://fiseti.com/bettercare'
//const BASE_URL = 'https://bettercare.co.mz/app_dm'
//const BASE_URL = 'https://bettercare.tech/app_dm'  
 
async function get(route, params) {
  let query =
    '?' +
    Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&')
  return axios.get(BASE_URL + query + route)
}

async function post(route, params) {
  return axios.post(BASE_URL + route, params)
}

const api = { get, post }

export default api
