import React, { useContext, useEffect, useState } from 'react'
import './PTP.css'

import { Center, Box } from '@chakra-ui/react'
import TopProviders from '../Top/TopProviders'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useHistory, Redirect } from 'react-router-dom'
import { AppContext } from '../core/app-context'
import api from '../core/api'
import EditIcon from '@material-ui/icons/Edit';

import Pagination from './Pagination'
import SearchPTP_Trans from '../Components/SearchPTP_Trans'

function PTP_Transactions(props) {
  const [appState, dispatch] = useContext(AppContext)

  const [providersTransactions, setprovidersTrans] = useState([])
  const [filteredTrans, setFilteredTrans] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [transPerPage, setTransPerPage] = useState(100)
  const [refreshKey, setRefreshKey] = useState(1)

  const [searchDate, setSearchDate] = useState()

  //Get Current Posts
  const indexOfLastTrans = currentPage * transPerPage
  const indexOfFirstTrans = indexOfLastTrans - transPerPage

  let history = useHistory()

  const refresh = () => {
    setRefreshKey((refreshKey) => refreshKey + 1)
  }

  useEffect(() => {
    api
      .post('/api.php', {
        action: 'get_provider_transactions',
        body: { provider_id: props.provider_id }
      })
      .then((result) => {
        console.log('ProvidersTrans', result.data.data)
        setprovidersTrans(result.data.data)
      })
      .catch((err) => {
        console.warn(err)
      })
  }, [])

  useEffect(() => {
    // console.log("members", members);
    setFilteredTrans(providersTransactions)
  }, [providersTransactions])

  const [clickedTrans, setClickedTrans] = useState('')

  function handleChange(e) {
    setClickedTrans(e.target.value)

    let isChecked = e.target.checked
    if (isChecked) {
      addT(e.target.value)

      props.setTransNo(props.transNo + 1)
    } else {
      handleRemoveItem(e.target.value)
      props.setTransNo(props.transNo - 1)
    }
  }

  const [Trans, setTrans] = useState([])

  const addT = (clickedTrans) => {
    const newTran = {
      trans_id: clickedTrans
    }

    const newTrans = [...Trans, newTran]

    setTrans(newTrans)
    props.setPostTrans(newTrans)
  }

  const handleRemoveItem = (clickedTrans) => {
    const Transz = Trans.filter((item) => item.trans_id != clickedTrans).map(({ trans_id }) => ({
      trans_id
    }))
    setTrans(Transz)
    props.setPostTrans(Transz)
  }

  const [facturaNo, setFacturaNo] = useState('')

  const [trans_id, setTid] = useState('')

  const [editing, setEditing] = useState(false)
  const [edited, setEdited] = useState(false)

  const [counter, setCounter] = useState(1)

  //If procedure isnt on the list allow for overide
  const here = () => {
    setCounter(counter + 1)

    if (counter % 2 !== 0) {
      setEditing(true)
    } else {
      setEditing(false)
    }
  }

  useEffect(() => {
    // console.log("members", members);
    setFilteredTrans(providersTransactions)
  }, [providersTransactions])

  const filterTrans = (filterForm) => {
    let newTrans = providersTransactions
    if (filterForm.auth_no.toString().length > 0) {
      newTrans = providersTransactions.filter(
        (m) =>
          filterForm.auth_no.toString().length > 0 &&
          m.auth_no.toString().includes(filterForm.auth_no.toString())
      )
    }
    // console.log("newMembers", newMembers);
    setFilteredTrans(newTrans)
  }

  useEffect(() => {
    if (!searchDate) {
      filterTrans({ auth_no: '' })
      return
    }

    try {
      const newTrans = providersTransactions.filter((m) => m.date == searchDate)
      setFilteredTrans(newTrans)
    } catch (error) {
      filterTrans({ auth_no: '' })
    }
  }, [searchDate])

  const [editID, setEditId] = useState('')

  const [editFormData, setEditFormData] = useState({
    procedure_name: '',
    factura_no: ' '
  })

  const handleEditFormChange = (event) => {
    const fieldName = event.target.getAttribute('name')
    const fieldValue = event.target.value

    const newFormData = { ...editFormData }
    newFormData[fieldName] = fieldValue

    setEditFormData(newFormData)
  }

  const handleEditClick = (transy) => {
    setEditId(transy.id)

    const formValues = {
      procedure_name: transy.procedure_name,
      first_names: transy.member.first_names,
      last_name: transy.member.last_name,
      price: transy.price,
      alt_price: transy.alt_price,
      auth_no: transy.auth_no,
      auth_by: transy.auth_by,
      date: transy.date,
      time: transy.time,
      factura_no: transy.factura_no
    }

    setEditFormData(formValues)
  }

  const handleEditFormSubmit = () => {
    const editedTrans = {
      id: editID,
      procedure_name: editFormData.procedure_name,
      first_names: editFormData.first_names,
      last_name: editFormData.last_name,
      price: editFormData.price,
      alt_price: editFormData.alt_price,
      auth_no: editFormData.auth_no,
      auth_by: editFormData.auth_by,
      date: editFormData.date,
      time: editFormData.time,
      factura_no: editFormData.factura_no
    }

    const newT = [...providersTransactions]

    const index = providersTransactions.findIndex(
      (providersTransactions) => providersTransactions.id === editID
    )

    newT[index] = editedTrans

    setprovidersTrans(newT)
    setEditId(null)
  }

  const SubmitTrans = () => {
    api
      .post('/api.php', {
        action: 'update_factura',

        body: {
          factura_no: editFormData.factura_no,
          transaction_id: trans_id
        }
      })
      .then(function (response) {
        console.log('Submitted info', response.data.message)
        if (response.data.status === 1) {
        } else {
          alert('You failed')
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '4px' }}>
      <div style={{ height: '85px' }}>
        <SearchPTP_Trans
          refresh={refresh}
          onSearch={filterTrans}
          setCurrentPage={setCurrentPage}
          setSearchDate={setSearchDate}
        />
      </div>

      <Box style={{ width: '100%' }}>
        <Center>
          <table className="main_table_ptp">
            <thead className="ptp_head">
              <th style={{ width: '5%' }}></th>
              <th style={{ width: '40%' }}>Procedimento</th>
              <th style={{ width: '15%' }}>Número de autorização</th>
              <th style={{ width: '15%' }}>Preço</th>
              <th style={{ width: '15%' }}>Data</th>
              <th style={{ width: '10%' }}>Factura No </th>
            </thead>

            {filteredTrans.slice(indexOfFirstTrans, indexOfLastTrans).map((e) => {
              return (
                <tr className="main_table_inside_ptp">
                  <td>
                    <input
                      style={{ width: '12px', height: '12px' }}
                      type="checkbox"
                      value={e.id}
                      onClick={(e) => {
                        handleChange(e)
                        setTid(e.target.value)
                        here()
                      }}
                    />
                  </td>
                  <td style={{ width: '50px' }}>
                    {e.procedure_name}
                    <span className="span_member">
                      {e.first_names} {e.last_name}
                    </span>
                  </td>
                  <td>
                    {e.auth_no}
                    <span className="span_member">{e.auth_by}</span>
                  </td>

                  <td>
                    {e.price} {e.price <= 100 ? 'sessao' : 'MT'}{' '}
                    {e.alt_price <= 100 ? ' ' : e.alt_price + ' MT'}
                  </td>
                  <td>
                    {e.date}
                    <span className="span_member">{e.time}</span>
                  </td>
                  <td>
                    {editID === e.id ? (
                      <input
                        name="factura_no"
                        className="input_claim"
                        value={editFormData.factura_no}
                        //onChange={({ target }) => setFacturaNo(target.value)}
                        onChange={handleEditFormChange}
                        style={{ width: '90%', display: 'inline-block' }}
                        type="text"
                      />
                    ) : (
                      <p>{e.factura_no} </p>
                    )}
                  </td>
                  {editID === e.id ? (
                    <td
                      onClick={() => {
                        handleEditFormSubmit()
                        SubmitTrans()
                      }}
                    >
                      <p style={{color:"#CD1D33"}}><EditIcon style={{height:'18px'}}/> </p>
                    </td>
                  ) : (
                    <td
                      onClick={() => {
                        handleEditClick(e)
                        setTid(e.id)
                      }}
                    >
                      <p className="link_btn" ><EditIcon style={{height:'18px'}} /> </p>
                    </td>
                  )}

                  {/* <td><p className={e.status === 'paid' ? 'paid_status' : ''}>{e.status==='paid'?'Pago': ''}</p></td> */}
                </tr>
              )
            })}
          </table>
        </Center>
      </Box>
    </div>
  )
}

export default PTP_Transactions
